import { createStandaloneToast } from '@chakra-ui/react'

import { GammaTheme } from 'modules/chakra/ChakraProviderGamma'

/**
 * A simple public javascript API exposed to the specified
 * scope that can be used outside the context of our app.
 *
 * Useful for things like experimenting or debugging.
 */
export const initPublicAPI = (
  scope: Record<string, any>,
  theme: GammaTheme
) => {
  if (scope.globalToast) {
    return
  }

  const toast = createStandaloneToast({ theme })

  // Expose a Chakra toast method that uses our theme
  scope.globalToast = toast
}
