import { useEffect } from 'react'

import { SUPPORTED_LOCALE_OPTIONS } from '../constants'
import { useLinguiLocale } from './useLinguiLocale'

export const useSyncLangHTMLAttr = () => {
  const locale = useLinguiLocale()
  useEffect(() => {
    const rtl = SUPPORTED_LOCALE_OPTIONS[locale]?.rtl === true

    document.documentElement.setAttribute('lang', locale)
    document.documentElement.setAttribute('dir', rtl ? 'rtl' : 'ltr')
  }, [locale])
}
