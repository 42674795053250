import { ChakraProvider, Theme, extendTheme } from '@chakra-ui/react'
import { gammaTheme } from '@gamma-app/ui'
import { PropsWithChildren, useEffect, useMemo } from 'react'

import {
  SUPPORTED_LOCALE_OPTIONS,
  SupportedLocaleKeyWithoutPseudoLocale,
} from 'modules/i18n/constants'
import { useLinguiLocale } from 'modules/i18n/hooks/useLinguiLocale'
import { initPublicAPI } from 'modules/public_api'

// @ts-ignore
export interface GammaTheme extends Theme {
  direction: 'rtl' | 'ltr'
}

export const ChakraProviderGamma = ({ children }: PropsWithChildren<{}>) => {
  const locale = useLinguiLocale()
  const supportedLocale =
    SUPPORTED_LOCALE_OPTIONS[locale as SupportedLocaleKeyWithoutPseudoLocale]
  const rtl = supportedLocale?.rtl === true
  const direction = rtl ? 'rtl' : 'ltr'

  const finalTheme = useMemo(
    () =>
      extendTheme(gammaTheme, {
        direction,
        styles: {
          global: {
            '.ProseMirror': {
              outline: '0 none',
            },
          },
        },
      }) as GammaTheme,
    [direction]
  )

  useEffect(() => {
    initPublicAPI(window, finalTheme)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ChakraProvider theme={finalTheme} resetCSS={true}>
      {children}
    </ChakraProvider>
  )
}
